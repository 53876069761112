<template>
  <!-- Hero Section -->
  <div class="container-fluid hero-section">
    <div class="row h-100 py-5 align-items-center justify-content-center text-center">
      <div class="col-md-7 pb-5 mb-5">
        <!-- Main heading with animation -->
        <h1 data-aos="fade-up" data-aos-duration="1800">
          Preserve the <span class="first-gradient">essence</span> <br />
          of your family <span class="second-gradient">culture</span>
        </h1>

        <!-- Call-to-action button with animation -->
        <router-link to="/waitlist"
          class="btn signature-btn mt-5"
          data-aos="fade-up"
          data-aos-duration="3000"
          >Get started</router-link>
      </div>
    </div>
  </div>

  <!-- About Section -->
  <section class="about-section">
    <div class="container py-5">
      <div class="row py-5 h-100 align-items-center">
        <!-- Left column with image -->
        <div class="col-md-6">
          <img
            data-aos="fade-up"
            data-aos-duration="3000"
            class="w-100"
            src="../assets/images/about.png"
            alt=""
          />
        </div>
        <!-- Right column with text content -->
        <div class="col-md-6">
          <h3 class="fs-38 mt-md-0 mt-4">
            Create a rich, interactive family history that captures your family's stories
            and traditions for generations to come!
          </h3>
          <p class="col-md-10">
            Kinfolk is a family connection app that goes beyond just building a family tree. With features for storytelling, event planning and much more. Kinfolk is designed to bring families closer together.
          </p>
          <a href="#" class="btn cta w-auto">Learn more</a>
        </div>
      </div>
    </div>
  </section>

  <!-- Features Section -->
  <div class="container features-section py-5">
    <div class="row py-md-5 h-100 align-items-top">
      <!-- Section heading -->
      <div class="col-md-12 pb-4">
        <h1 class="text-center fs-58" data-aos="fade-up" data-aos-duration="1000">
          Kinfolk's amazing features
        </h1>
      </div>
      <!-- Feature cards -->
      <!-- Family Events feature -->
      <div class="col-md-3" data-aos="fade-up" data-aos-duration="1000">
        <div class="card text-center bg-white">
          <div class="card-header">
            <img src="../assets/images/home/Maskgroup(7).png" alt="Family Events" />
          </div>
          <div class="card-body">
            <h5>Family Events</h5>
            <p>
              Plan and organize family gatherings while creating a lasting record of the event.
            </p>
          </div>
        </div>
      </div>
      <!-- Family Tree feature -->
      <div class="col-md-3" data-aos="fade-up" data-aos-duration="1500">
        <div class="card text-center bg-white">
          <div class="card-header">
            <img src="../assets/images/home/Maskgroup(6).png" alt="Family Tree" />
          </div>
          <div class="card-body">
            <h5>Family Tree</h5>
            <p>Build out your family tree network when you invite new kin .</p>
          </div>
        </div>
      </div>
      <!-- Culture feature -->
      <div class="col-md-3" data-aos="fade-up" data-aos-duration="2000">
        <div class="card text-center bg-white">
          <div class="card-header">
            <img   src="../assets/images/home/Maskgroup(5).png" alt="Culture" />
          </div>
          <div class="card-body">
            <h5>Culture</h5>
            <p>Preserve customs, values, stories that come together to create a family culture.</p>
          </div>
        </div>
      </div>
      <!-- Timelines feature -->
      <div class="col-md-3" data-aos="fade-up" data-aos-duration="2500">
        <div class="card text-center bg-white">
          <div class="card-header">
            <img   src="../assets/images/home/Maskgroup(4).png" alt="Timelines" />
          </div>
          <div class="card-body">
            <h5>Timelines</h5>
            <p>
              Create stories from memories using a variety of media formats.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Be a Part Section -->
  <div class="container be-apart-section p-md-5 p-4 my-5">
    <div class="row h-100 p-md-4 align-items-center">
      <!-- Left column with text content -->
      <div class="col-md-6">
        <h3 class="fs-34">Be part of something bigger:</h3>
        <ol>
          <li>
            By preserving family memories and traditions, kinfolk can help users reflect on their roots and identity, providing a sense of meaning and purpose. Reflecting on our personal history and values can give us a greater sense of fulfillment and direction in life.
          </li>
          <li></li>
          <li>
            Kinfolk allows users to leave a lasting legacy for future generations. Knowing that our stories and traditions will be passed down can give us a sense of fulfillment and purpose, knowing that we are contributing to something larger than ourselves.
          </li>
        </ol>
        <a href="#" class="btn cta w-auto">Learn more</a>
      </div>
      <!-- Right column with image -->
      <div class="col-md-6 mt-md-0 mt-5">
        <img
          class="w-100"
          data-aos="fade-up"
          data-aos-duration="2500"
          src="../assets/images/be-apart.png"
          alt="Be a part of something bigger"
        />
      </div>
    </div>
  </div>

  <!-- Spacer -->
  <div class="py-4"></div>

  <!-- Legacy Section -->
  <div class="container-fluid legacy-section p-x-5 px-4 mt-5">
    <div class="row h-100 align-items-center">
      <div class="col-md-12">
        <h1 class="text-center fs-58">With kinfolk you leave a lasting legacy</h1>
      </div>
    </div>
  </div>

  <!-- Pricing Section -->
  <div class="container-fluid pricing-section px-md-5 pblock100 mb-5">
    <div class="row h-100 py-5 align-items-center">
      <!-- Pricing heading -->
      <div
        class="col-md-6 mx-auto text-center text-white"
        data-aos="fade-up"
        data-aos-duration="1800"
      >
        <h3 class="fs-38">Simple pricing, for everyone</h3>
        <p class="fs-21 aonik-light">
          KinFolk charges a subscription plan of $10 per month for users who wish to tap into kinfolks potential
        </p>
      </div>
      <!-- Pricing packages component -->
      <div class="col-md-7 mx-auto text-center text-white">
        <packages />
      </div>
    </div>
  </div>

  <!-- FAQ Section -->
  <Faq />
</template>

<script>
import Packages from "./Packages.vue";
import Faq from "./Faq.vue";

export default {
  // Component name
  name: "Home",

  // Imported components
  components: { Packages, Faq },

  // Props (not used in this component)
  props: {
    msg: String,
  },

  // Component data
  data() {
    return {
      pageTitle: "Kinfolk",
    };
  },

  // Lifecycle hook: called after the component is mounted
  mounted() {
    // Set the document title
    document.title = this.pageTitle;
  },
};
</script>

<!-- Scoped styles for this component -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
