``
<template>
  <div v-if="successMessage" class="toast alert-success show">
    <div class="toast-body">
      <div class="row align-items-center">
        <div class="col-10" style="font-size: 16px">{{ successMessage }}</div>
        <div class="col-2">
          <!--          <button type="button" class="ml-2 mb-1 close border-0 bg-transparent" data-dismiss="toast" aria-label="Close">-->
          <!--            <span aria-hidden="true">&times;</span>-->
          <!--          </button>-->
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-7 text-center">
    <div class="d-md-flex justify-content-between">
      <h4 class="m-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="44"
          height="33"
          viewBox="0 0 44 33"
          fill="none"
        >
          <rect width="44" height="33" rx="8" fill="#CECECE" />
          <path d="M4 8L22.5 15L41 8" stroke="white" stroke-width="2" />
        </svg>
        <!-- Subscribe to our newsletter -->
        Get notified when kinfolk goes live
      </h4>
      <router-link to="/waitlist" class="btn signature-btn py-1 px-3"
        >Join Waitlist</router-link
      >
    </div>
    <!-- <div class="newsletter position-relative">
      <input type="email" v-model="email" placeholder="Enter e-mail address" required />

      <button @click="postData()" class="btn signature-btn" :disabled="!isEmailValid">
        <span v-if="isLoading">Loading...</span>

        <span v-if="!isLoading">Get started</span>
      </button>
      <p v-if="emailError">{{ emailError }}</p>
    </div> -->
  </div>
</template>

<script>
const apiUrl = process.env.VUE_APP_API_BASE_URL;
import axios from "axios";
export default {
  name: "Newsletter",

  data() {
    return {
      email: "",
      emailError: "",
      successMessage: "",
      isEmailValid: false,
      isLoading: false,
    };
  },
  watch: {
    email: function (newEmail) {
      this.isEmailValid = this.isValidEmail(newEmail);
    },
  },
  methods: {
    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },

    postData() {
      if (!this.isEmailValid) {
        this.emailError = "Invalid email address.";
      } else {
        this.emailError = "";
        // Perform additional actions when email is valid
      }

      console.log(apiUrl);

      const data = { email: this.email };
      this.isLoading = true; // Set loading state to true
      axios
        .post(apiUrl + "newsletter-subscribe", data)
        .then((response) => {
          this.successMessage = response.data.message;
          setTimeout(() => {
            this.successMessage = false;
          }, 4000);
        })

        .catch((error) => {
          this.errorMessage = error.response.data.message; // Update the error message
          setTimeout(() => {
            this.errorMessage = false;
            // this.isLoading = false;
          }, 2000);
          // alert(error); // Handle any errors
        })
        .finally(() => {
          this.isLoading = false; // Set loading state to false regardless of success or error
        });
    },
  },
};
</script>

<style>
.toast {
  position: fixed;
  top: 5%;
  right: 1%;
  background: #198754ad !important;
  z-index: 99999;
  color: white !important;
}
</style>
