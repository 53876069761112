<template>
  <head>
    <title>
      {{ pageTitle }}
    </title>
  </head>
  <div id="section-header">
    <header>
      <b-navbar
        toggleable="lg"
        type="light"
        variant="white"
        class="navbar navbar-expand-lg bg-white"
      >
        <div class="container">
          <router-link class="navbar-brand" to="/">
            <img src="../assets/images/logo.png" alt="" />
          </router-link>
          <div class="mob-show" role="search">
            <router-link to="" class="btn signature-btn">Get started</router-link>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="25"
              viewBox="0 0 26 25"
              fill="none"
            >
              <path
                d="M2 1.5H24"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M2 12.5H24"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M2 23.5H24"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link class="nav-link" aria-current="page" :class="{ active: $route.path === '/' }" to="/">Home</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :class="{ active: $route.path === '/about' }" to="/about"> About </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/how-its-work"
                  :class="{ active: $route.path === '/how-its-work' }"
                  >How it works
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/support"
                  :class="{ active: $route.path === '/support' }"
                >Support</router-link>
              </li>
            </ul>
            <div class="d-flex desktop-show" role="search">
              <router-link class="btn signature-btn" to="/waitlist">Get started</router-link>
            </div>
          </div>
        </div>
      </b-navbar>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      pageTitle: 'kinfolk',
    };
  },
};
</script>

<style>
.cta {
  color: #1665C4 !important;
}
.signature-btn{
  color: #fff !important;

}
.signature-btn.transparent{
  color: #1665C4 !important;

}
.navbar {
  background-color: #ffffff !important;
}

.navbar .nav-link {
  color: #000000 !important;
}

.navbar .nav-link:hover,
.navbar .nav-link.active {
  color: #1665C4 !important;
}
</style>